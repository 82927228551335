import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, smoothScroll } from './lib/scroll'
import { StoreLocatorView } from './lib/store-locator.js'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  new StoreLocatorView({ el: '#store-locator' })

  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  if (window.location.pathname === '/') {
    smoothScroll('a[href*="/#"]', { offset: 220 })
  };

  $('#age-agree-btn').click(function () {
    // set sessionStorage on click
    localStorage.setItem('hide', true)
    $('#age').hide()
  })

  if (localStorage.getItem('hide')) {
    // When sessionStorage is set hide the modal
    $('#age').hide()
  } else {
    $('#age').show()
  }

  $('.burger-menu').click(function () {
    $(this).toggleClass('open')
  })

  $('#menu li a').click(function () {
    $('.burger-menu').removeClass('open')
  })
})

$(document).scroll(function () {
  const scroll = $(this).scrollTop()
  if (scroll > 100) {
    $('.header').addClass('fixed')
  } else {
    $('.header').removeClass('fixed')
  };
})
